import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveResizedImage } from '@wix/wix-vod-shared/dist/src/widget/ui-components/responsive-resized-image/responsive-resized-image';
var ImageCover = function (_a) {
    var width = _a.width, height = _a.height, posterUrl = _a.posterUrl, alt = _a.alt;
    return (React.createElement(ResponsiveResizedImage, { src: posterUrl, width: width, height: height, withFallbackColor: !posterUrl, progressive: true, nonProportional: true, alt: alt, stretchToContainer: true, breakpoints: [
            {
                min: 0,
                width: width,
                height: height,
            },
        ] }));
};
ImageCover.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    posterUrl: PropTypes.string,
};
export default ImageCover;
