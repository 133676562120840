import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StatisticLogger from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/loggers/statistic-logger';
import ErrorLogger from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/loggers/error-logger';
import PlaybackSuccessLogger from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/loggers/playback-success-logger';
import { logBi } from 'shared/worker/actions/bi';
var Loggers = /** @class */ (function (_super) {
    __extends(Loggers, _super);
    function Loggers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.logPlaybackTry = function (data) {
            _this.props.logBi('player-play-requested', __assign(__assign({}, data), { isAutoplay: _this.props.isAutoplayed }));
        };
        _this.logPlaybackSuccess = function (data) {
            _this.props.logBi('player-play-done', __assign(__assign({}, data), { isAutoplay: _this.props.isAutoplayed }));
        };
        _this.logError = function (data) {
            _this.props.logBi('player-error', data);
        };
        _this.logStatistic = function (data) {
            _this.props.logBi('player.debug.info', __assign(__assign({}, data), { isAutoplay: _this.props.isAutoplayed }));
        };
        return _this;
    }
    Loggers.prototype.render = function () {
        var _a = this.props, engine = _a.engine, eventEmitter = _a.eventEmitter, videoItem = _a.videoItem, channelData = _a.channelData, isVideoPlaying = _a.isVideoPlaying, isEmbed = _a.isEmbed, isLive = _a.isLive;
        return (React.createElement("section", null,
            React.createElement(StatisticLogger, { videoID: videoItem.baseItemId, isEmbed: isEmbed, isLive: isLive, channelID: channelData.id, engine: engine, logFunction: this.logStatistic, shouldSendBI: isVideoPlaying }),
            React.createElement(PlaybackSuccessLogger, { videoID: videoItem.baseItemId, engine: engine, eventEmitter: eventEmitter, logTryFunction: this.logPlaybackTry, logSuccessFunction: this.logPlaybackSuccess, channelID: channelData.id }),
            React.createElement(ErrorLogger, { engine: engine, eventEmitter: eventEmitter, logFunction: this.logError, videoID: videoItem.baseItemId, isLive: isLive })));
    };
    Loggers.propTypes = {
        isAutoplayed: PropTypes.bool,
        isEmbed: PropTypes.bool,
        engine: PropTypes.object,
        eventEmitter: PropTypes.object,
        channelData: PropTypes.object,
        videoItem: PropTypes.object,
        isVideoPlaying: PropTypes.bool,
        isLive: PropTypes.bool,
    };
    Loggers.defaultProps = {
        isEmbed: false,
        isLive: false,
    };
    Loggers = __decorate([
        connect(null, { logBi: logBi })
    ], Loggers);
    return Loggers;
}(React.Component));
export default Loggers;
