import { __assign, __decorate, __extends } from "tslib";
import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isExternalVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import ExternalPlayer from '@wix/wix-vod-shared/dist/src/widget/player-external/player-external';
import NotAvailableOverlay from './components/not-available-overlay/not-available-overlay';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { MAIN_ITEM_TYPE_VALUES } from '@wix/wix-vod-constants/dist/app-settings/main-item-type-values';
import { setFullScreenStatus } from 'widget/redux/client/actions/player/set-fullscreen-status';
import { setVolumeChangedByUser } from 'widget/redux/client/actions/player/sound';
import { isAllCardsOverlayVisible } from 'widget/selectors/layout';
import { isInFullScreen } from 'shared/selectors/player';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import InternalPlayer from './player-internal';
import ChannelCover from 'widget/containers/channel-cover/channel-cover';
import { withStyles } from 'shared/utils/withStyles';
import styles from './player.styl';
import { getLocale } from 'shared/selectors/app-settings';
import { ResponsiveResizedImage } from '@wix/wix-vod-shared/dist/src/widget/ui-components/responsive-resized-image/responsive-resized-image';
import { getCompId } from 'widget/redux/client/hydrated-data/hydrated-data';
var Player = /** @class */ (function (_super) {
    __extends(Player, _super);
    function Player(props) {
        var _this = _super.call(this, props) || this;
        _this.setStylesForRef = function (ref) {
            if (!ref) {
                return;
            }
            var _a = _this.getStyles(), width = _a.width, height = _a.height;
            ref.style.width = width + "px";
            ref.style.height = height + "px";
        };
        _this.saveIframeRef = function (ref) {
            _this.iframeRef = ref;
        };
        _this.savePlayerWrapperRef = function (ref) {
            _this.playerWrapperRef = ref;
        };
        _this.setPlayStatus = function () {
            var isVideoPlaying = _this.props.isVideoPlaying;
            if (!isVideoPlaying) {
                var beenPlayed = _this.state.beenPlayed;
                if (beenPlayed) {
                    _this.onResume();
                }
                else {
                    _this.onPlayStart();
                }
            }
        };
        _this.onPlayStart = function () {
            _this.props.onPlayStart();
            _this.setState({ beenPlayed: true });
        };
        _this.onEnded = function () {
            _this.setState({ ended: true, beenPlayed: false });
            _this.props.onEnded();
        };
        _this.onResume = function () {
            _this.props.onResume();
            _this.setState({ ended: false });
        };
        _this.onFullScreenChanged = function (isInFullScreen) {
            var _a = _this.props, setFullScreenStatus = _a.setFullScreenStatus, onFullScreenChanged = _a.onFullScreenChanged;
            setFullScreenStatus(isInFullScreen);
            onFullScreenChanged(isInFullScreen);
        };
        _this.onVolumeChange = function () {
            _this.props.setVolumeChangedByUser();
        };
        _this.onMuteChange = function () {
            _this.props.setVolumeChangedByUser();
        };
        _this.playerWrapperRef = null;
        _this.iframeRef = null;
        _this.state = {
            beenPlayed: false,
            ended: false,
            mounted: false,
        };
        _this.playerRef = React.createRef();
        _this.overlayRef = React.createRef();
        return _this;
    }
    Player.prototype.componentDidMount = function () {
        var playerRef = this.playerRef.current;
        var overlayRef = this.overlayRef.current;
        [playerRef, overlayRef].forEach(this.setStylesForRef);
    };
    Player.prototype.UNSAFE_componentWillReceiveProps = function (newProps) {
        if (this.isVideoIdChanged(newProps)) {
            this.setState({ beenPlayed: false, ended: false });
        }
    };
    Player.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, isVideoPlaying = _a.isVideoPlaying, isExternalVideo = _a.isExternalVideo;
        var isVideoPlayingChanged = prevProps.isVideoPlaying !== isVideoPlaying;
        if (isVideoPlayingChanged && isVideoPlaying) {
            focus(isExternalVideo ? this.iframeRef : this.playerWrapperRef);
        }
    };
    Player.prototype.isVideoIdChanged = function (newProps) {
        return newProps.videoItem.id !== this.props.videoItem.id;
    };
    Object.defineProperty(Player.prototype, "showChannelCover", {
        get: function () {
            var _a = this.props, appSettings = _a.appSettings, canShowChannelCover = _a.canShowChannelCover;
            return (canShowChannelCover &&
                appSettings.numbers.mainItemType === MAIN_ITEM_TYPE_VALUES.CHANNEL_COVER);
        },
        enumerable: true,
        configurable: true
    });
    Player.prototype.showVideoTitle = function () {
        return _.get(this.props, 'appSettings.booleans.showVideoTitle', true);
    };
    Player.prototype.shouldShowInitialOverlay = function () {
        var _a = this.props, isVideoPlayingOptimistic = _a.isVideoPlayingOptimistic, isVideoPausedOptimistic = _a.isVideoPausedOptimistic, isVideoPlayAborted = _a.isVideoPlayAborted, isInFullScreen = _a.isInFullScreen, showInitialOverlayOnPause = _a.showInitialOverlayOnPause, isAllCardsOverlayVisible = _a.isAllCardsOverlayVisible;
        if (isAllCardsOverlayVisible) {
            return false;
        }
        if (showInitialOverlayOnPause) {
            return isInFullScreen ? false : !isVideoPlayingOptimistic;
        }
        return (isVideoPlayAborted ||
            (!isVideoPlayingOptimistic && !isVideoPausedOptimistic));
    };
    Object.defineProperty(Player.prototype, "posterUrl", {
        get: function () {
            var _a = this.props, videoItem = _a.videoItem, channelData = _a.channelData;
            var customCoverUrl = videoItem.customCoverUrl;
            var coverUrl = videoItem.coverUrl;
            coverUrl = customCoverUrl || coverUrl;
            if (this.showChannelCover) {
                coverUrl = channelData.customCoverUrl || channelData.coverUrl || coverUrl;
            }
            return coverUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Player.prototype, "initialOverlay", {
        get: function () {
            var children = this.props.children;
            var _a = this.state, beenPlayed = _a.beenPlayed, ended = _a.ended;
            if (!children) {
                return null;
            }
            var channelData = this.props.channelData;
            var originProps = _.pick(this.props, 'showChannelCover', 'appSettings', 'videoItem');
            return React.cloneElement(children, _.assign({
                channelData: channelData,
                beenPlayed: beenPlayed,
                ended: ended,
                showChannelCover: this.showChannelCover,
            }, originProps));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Player.prototype, "poster", {
        get: function () {
            var _a = this.props, simple = _a.simple, isExternalVideo = _a.isExternalVideo, showPoster = _a.showPoster, width = _a.width, height = _a.height, videoItem = _a.videoItem;
            if (!showPoster) {
                return null;
            }
            if (simple && isExternalVideo) {
                return null;
            }
            var posterUrl = this.posterUrl;
            if (!posterUrl) {
                return null;
            }
            var channelData = this.props.channelData;
            // TODO: use separated video players for dashboard and widget !!!
            if (channelData) {
                return (React.createElement(ChannelCover, { posterUrl: posterUrl, mediaInfo: channelData.mediaInfo, channelId: channelData.id, channelTitle: channelData.title, videoTitle: videoItem.title, showChannelCover: this.showChannelCover, width: width, height: height }));
            }
            return (React.createElement(ResponsiveResizedImage, { styleName: "cover-image", src: posterUrl, width: width, height: height, nonProportional: true, progressive: true }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Player.prototype, "overlay", {
        get: function () {
            if (!this.shouldShowInitialOverlay()) {
                return null;
            }
            return (React.createElement("div", { styleName: "overlay", ref: this.overlayRef, style: this.getStyles() },
                this.initialOverlay,
                this.poster));
        },
        enumerable: true,
        configurable: true
    });
    Player.prototype.getStyles = function () {
        var _a = this.props, width = _a.width, height = _a.height;
        return { width: width, height: height };
    };
    Object.defineProperty(Player.prototype, "player", {
        get: function () {
            var _a;
            var _b = this.props, isOverQuota = _b.isOverQuota, width = _b.width, height = _b.height, shouldRenderInternalPlayer = _b.shouldRenderInternalPlayer, isMuted = _b.isMuted, onPause = _b.onPause;
            var props = _.omit(this.props, 'intl', 'styles');
            // NOTE: we need to put share overlay inside VideoPlayer
            // to be sure player would be shown in full screen mode
            if (isOverQuota) {
                return React.createElement(NotAvailableOverlay, { width: width, height: height });
            }
            return (React.createElement("div", null,
                React.createElement("div", { className: classnames((_a = {},
                        _a[styles.hidden] = this.props.isExternalVideo,
                        _a)) }, shouldRenderInternalPlayer && (React.createElement(InternalPlayer, __assign({}, props, { muted: isMuted, canShowTitle: this.showVideoTitle(), onFullScreenChanged: this.onFullScreenChanged, onPlay: this.setPlayStatus, onPause: onPause, onEnded: this.onEnded, onVolumeChange: this.onVolumeChange, onMuteChange: this.onMuteChange })))),
                this.props.isExternalVideo && (React.createElement(ExternalPlayer, __assign({}, props, { locale: this.props.locale, muted: isMuted, onFullScreenChanged: this.onFullScreenChanged, onPlay: this.setPlayStatus, onPause: onPause, onEnded: this.onEnded, onIframeRef: this.saveIframeRef, onVolumeChange: this.onVolumeChange })))));
        },
        enumerable: true,
        configurable: true
    });
    Player.prototype.render = function () {
        var _a = this.props, dataHook = _a.dataHook, showPlayer = _a.showPlayer;
        return (React.createElement("div", { styleName: "player", ref: this.playerRef, "data-hook": dataHook, style: this.getStyles() },
            this.overlay,
            showPlayer && (React.createElement("div", { styleName: "player-wrapper", dir: "ltr", ref: this.savePlayerWrapperRef, "aria-hidden": this.shouldShowInitialOverlay() }, this.player))));
    };
    Player.propTypes = {
        appSettings: PropTypes.object,
        channelData: PropTypes.object,
        videoItem: PropTypes.object.isRequired,
        compId: PropTypes.string.isRequired,
        canShowChannelCover: PropTypes.bool,
        isVideoPlayRequested: PropTypes.bool,
        isVideoPauseRequested: PropTypes.bool,
        isVideoPlaying: PropTypes.bool,
        isVideoPaused: PropTypes.bool,
        isVideoPlayingOptimistic: PropTypes.bool,
        isVideoPausedOptimistic: PropTypes.bool,
        isVideoPlayAborted: PropTypes.bool,
        isInFullScreen: PropTypes.bool,
        isMuted: PropTypes.bool,
        shouldRenderInternalPlayer: PropTypes.bool,
        showPlayer: PropTypes.bool,
        showInitialOverlayOnPause: PropTypes.bool,
        showPoster: PropTypes.bool,
        simple: PropTypes.bool,
        mobileMode: PropTypes.bool,
        isExternalVideo: PropTypes.bool,
        children: PropTypes.any,
        width: PropTypes.number,
        height: PropTypes.number,
        abortPlayVideo: PropTypes.func,
        onPause: PropTypes.func,
        onPlayStart: PropTypes.func,
        onResume: PropTypes.func,
        onEnded: PropTypes.func,
        onFullScreenChanged: PropTypes.func,
        setFullScreenStatus: PropTypes.func,
        setVolumeChangedByUser: PropTypes.func,
        videoUrls: PropTypes.object,
        isOverQuota: PropTypes.bool,
        isInLightbox: PropTypes.bool,
        isAllCardsOverlayVisible: PropTypes.bool,
        dataHook: PropTypes.string,
    };
    Player.defaultProps = {
        onPause: _.noop,
        onPlayStart: _.noop,
        onResume: _.noop,
        onEnded: _.noop,
        showPlayer: true,
        showPoster: true,
        dataHook: 'player',
    };
    Player = __decorate([
        connect(function (state, ownProps) { return ({
            isExternalVideo: isExternalVideo(ownProps.videoItem),
            isInFullScreen: isInFullScreen(state),
            shouldRenderInternalPlayer: !(ownProps.mobileMode && isExternalVideo(ownProps.videoItem)),
            isAllCardsOverlayVisible: isAllCardsOverlayVisible(state),
            locale: getLocale(state),
            compId: getCompId(state),
        }); }, {
            setFullScreenStatus: setFullScreenStatus,
            setVolumeChangedByUser: setVolumeChangedByUser,
        }),
        withStyles(styles),
        VODCSSModules(styles)
    ], Player);
    return Player;
}(React.Component));
export default Player;
