import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { callOnEnterPressExactOnElement } from 'shared/utils/call-on-keyboard-press';
import ThumbnailOverlayStrip from './_thumbnail-overlay/thumbnail-overlay';
import { ResponsiveSimpleVideoThumbnail } from 'widget/components/thumbnail/simple';
import { withStyles } from 'shared/utils/withStyles';
import styles from './video-list-item.styl';
var VideoListItem = /** @class */ (function (_super) {
    __extends(VideoListItem, _super);
    function VideoListItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoListItem.prototype.render = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem, width = _a.width, height = _a.height, appSettings = _a.appSettings, isFocusable = _a.isFocusable, onClick = _a.onClick, onPlayRequest = _a.onPlayRequest, currentSiteUser = _a.currentSiteUser;
        var onClickWithVideo = memoizedPartial(onClick, videoItem);
        return (React.createElement("section", { className: styles.container, onClick: onClickWithVideo, onKeyDown: memoizedPartial(callOnEnterPressExactOnElement, onClickWithVideo), style: { height: height, width: width }, "data-hook": "video-list-thumb-wrapper", "aria-hidden": !isFocusable, tabIndex: isFocusable ? 0 : -1 },
            React.createElement(ResponsiveSimpleVideoThumbnail, { video: videoItem, width: width, height: height }),
            React.createElement(ThumbnailOverlayStrip, { channelData: channelData, videoItem: videoItem, width: width, showChannelCover: false, appSettings: appSettings, onPlaySelectedVideo: memoizedPartial(onPlayRequest, videoItem), isContentFocusable: isFocusable, currentSiteUser: currentSiteUser })));
    };
    VideoListItem.propTypes = {
        appSettings: PropTypes.object.isRequired,
        channelData: PropTypes.object.isRequired,
        videoItem: PropTypes.object.isRequired,
        currentSiteUser: PropTypes.object,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        isFocusable: PropTypes.bool.isRequired,
        onPlayRequest: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
    };
    VideoListItem = __decorate([
        withStyles(styles)
    ], VideoListItem);
    return VideoListItem;
}(React.Component));
export default VideoListItem;
