import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import classnames from 'classnames';
import { areElementsRounded } from 'shared/selectors/app-settings';
import socialNetworkNames from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';
import SocialButton from './social-button/social-button';
import { withStyles } from 'shared/utils/withStyles';
import styles from './social-share.styl';
var SocialShare = /** @class */ (function (_super) {
    __extends(SocialShare, _super);
    function SocialShare() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SocialShare.prototype.render = function () {
        var _a = this.props, areElementsRounded = _a.areElementsRounded, networks = _a.networks, content = _a.content, className = _a.className, popoutOuterClassName = _a.popoutOuterClassName, popoutInnerClassName = _a.popoutInnerClassName, buttonClassName = _a.buttonClassName, networksClassNames = _a.networksClassNames, linkClassName = _a.linkClassName, onShare = _a.onShare, children = _a.children, channelId = _a.channelId, videoId = _a.videoId, isButtonsFocusable = _a.isButtonsFocusable;
        return (React.createElement("div", { className: classnames(styles['social-share'], className), "data-hook": "share-options" },
            _.map(networks, function (network) {
                var ariaLabel = network === socialNetworkNames.LINK
                    ? i18n.t('widget.accessibility.get-link')
                    : i18n.t('widget.accessibility.share-on', { network: network });
                return (React.createElement(SocialButton, { popoutOuterClassName: popoutOuterClassName, popoutInnerClassName: popoutInnerClassName, className: classnames(buttonClassName, networksClassNames[network]), rounded: areElementsRounded, key: network, network: network, content: content, linkClassName: linkClassName, onClick: onShare, ariaLabel: ariaLabel, isFocusable: isButtonsFocusable, channelId: channelId, videoId: videoId }));
            }),
            children));
    };
    SocialShare.propTypes = {
        areElementsRounded: PropTypes.bool,
        networks: PropTypes.arrayOf(PropTypes.string),
        content: PropTypes.shape({
            siteUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            thumbnailUrl: PropTypes.string,
        }),
        className: PropTypes.string,
        buttonClassName: PropTypes.string,
        linkClassName: PropTypes.string,
        popoutOuterClassName: PropTypes.string,
        popoutInnerClassName: PropTypes.string,
        networksClassNames: PropTypes.object,
        onShare: PropTypes.func,
        children: PropTypes.node,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        isButtonsFocusable: PropTypes.bool,
    };
    SocialShare.defaultProps = {
        networks: _.values(socialNetworkNames),
        networksClassNames: {},
        onShare: _.noop,
    };
    SocialShare = __decorate([
        connect(function (state) { return ({ areElementsRounded: areElementsRounded(state) }); }),
        withStyles(styles)
    ], SocialShare);
    return SocialShare;
}(React.Component));
export default SocialShare;
