var _a;
import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import classnames from 'classnames';
import { connect } from 'react-redux';
import SOCIAL_NETWORK_NAMES from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';
import { notForPreview } from 'shared/utils/not-for-preview';
import openPopup from '@wix/wix-vod-shared/dist/src/common/utils/open-popup';
import initSocialNetworks from '../social-networks';
import { getCompId, getCurrentPageId, getBiToken, getInstanceId, } from 'widget/redux/client/hydrated-data/hydrated-data';
import FacebookIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/facebook';
import TwitterIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/twitter';
import PinterestIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/pinterest';
import TumblrIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/tumblr';
import LinkIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/link';
import { withStyles } from 'shared/utils/withStyles';
import styles from './social-button.styl';
var SOCIAL_NETWORK_NAME_TO_ICON_MAP = (_a = {},
    _a[SOCIAL_NETWORK_NAMES.FACEBOOK] = FacebookIcon,
    _a[SOCIAL_NETWORK_NAMES.TWITTER] = TwitterIcon,
    _a[SOCIAL_NETWORK_NAMES.PINTEREST] = PinterestIcon,
    _a[SOCIAL_NETWORK_NAMES.TUMBLR] = TumblrIcon,
    _a[SOCIAL_NETWORK_NAMES.LINK] = LinkIcon,
    _a);
var SocialButton = /** @class */ (function (_super) {
    __extends(SocialButton, _super);
    function SocialButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showPopout: false,
        };
        _this.handleShareIconClick = function (event) {
            event.preventDefault();
            event.stopPropagation();
            _this.openShareWindow();
            var _a = _this.props, onClick = _a.onClick, network = _a.network;
            onClick(network);
        };
        _this.openShareWindow = notForPreview(function () {
            openPopup(_this.getShareUrl());
        });
        _this.togglePopout = notForPreview(function (event) {
            event.preventDefault();
            event.stopPropagation();
            var showPopout = _this.state.showPopout;
            _this.setState({
                showPopout: !showPopout,
            });
        });
        _this.handleCopyToClipboard = notForPreview(function (event) {
            event.preventDefault();
            if (document.queryCommandSupported('copy')) {
                _this.input.select();
                document.execCommand('copy');
            }
        });
        return _this;
    }
    SocialButton.prototype.UNSAFE_componentWillMount = function () {
        var network = this.props.network;
        this.socialNetwork = initSocialNetworks()[network];
    };
    SocialButton.prototype.componentDidMount = function () {
        var _a = this, link = _a.link, popout = _a.popout, copyLink = _a.copyLink;
        // We use addEventListener because react`s elements are destroyed before onClick is fired
        if (popout) {
            copyLink.addEventListener('click', this.handleCopyToClipboard);
            popout.addEventListener('click', this.stopPropagation);
            link.addEventListener('click', this.togglePopout);
        }
        else {
            link.addEventListener('click', this.handleShareIconClick);
        }
    };
    SocialButton.prototype.componentWillUnmount = function () {
        var _a = this, link = _a.link, popout = _a.popout, copyLink = _a.copyLink;
        if (popout) {
            copyLink.removeEventListener('click', this.handleCopyToClipboard);
            popout.removeEventListener('click', this.stopPropagation);
            link.removeEventListener('click', this.togglePopout);
        }
        else {
            link.removeEventListener('click', this.handleShareIconClick);
        }
    };
    SocialButton.prototype.stopPropagation = function (event) {
        event.stopPropagation();
    };
    SocialButton.prototype.saveRef = function (name) {
        var _this = this;
        return function (element) {
            _this[name] = element;
        };
    };
    Object.defineProperty(SocialButton.prototype, "popoutContent", {
        get: function () {
            var _a;
            var _b = this.props, network = _b.network, popoutInnerClassName = _b.popoutInnerClassName, popoutOuterClassName = _b.popoutOuterClassName;
            var showPopout = this.state.showPopout;
            if (network !== 'link') {
                return null;
            }
            var styleOuter = classnames(styles.popout, popoutOuterClassName, (_a = {},
                _a[styles.hide] = !showPopout,
                _a));
            var styleInner = classnames(styles['popout-inner'], popoutInnerClassName);
            return (React.createElement("span", { className: styleOuter, ref: this.saveRef('popout') },
                React.createElement("span", { className: styleInner },
                    React.createElement("input", { type: "text", value: this.getShareUrl(), readOnly: true, className: styles['copy-url-input'], ref: this.saveRef('input') }),
                    React.createElement("a", { href: "#", className: styles['copy-url-link'], ref: this.saveRef('copyLink') }, i18n.t('social-button.copy')))));
        },
        enumerable: true,
        configurable: true
    });
    SocialButton.prototype.getShareUrl = function () {
        var _a = this.props, content = _a.content, instanceId = _a.instanceId, componentId = _a.componentId, sitePageId = _a.sitePageId, channelId = _a.channelId, videoId = _a.videoId, biToken = _a.biToken;
        return this.socialNetwork.getShareUrl(content, {
            instanceId: instanceId,
            componentId: componentId,
            sitePageId: sitePageId,
            channelId: channelId,
            videoId: videoId,
            biToken: biToken,
        });
    };
    SocialButton.prototype.render = function () {
        var _a;
        var _b = this.props, network = _b.network, className = _b.className, rounded = _b.rounded, linkClassName = _b.linkClassName, ariaLabel = _b.ariaLabel, isFocusable = _b.isFocusable;
        var name = this.socialNetwork.name;
        var tooltipStyles = classnames(className, styles.tooltip);
        var linkStyles = classnames(styles['social-button'], network, linkClassName, (_a = {},
            _a[styles.rounded] = rounded,
            _a));
        var Icon = SOCIAL_NETWORK_NAME_TO_ICON_MAP[network];
        return (React.createElement("span", { className: tooltipStyles },
            React.createElement("a", { href: "#", role: "button", "data-hook": network, title: ariaLabel || i18n.t("social-button." + name), className: linkStyles, ref: this.saveRef('link'), tabIndex: isFocusable ? 0 : -1, "aria-label": ariaLabel },
                React.createElement(Icon, { role: "presentation" })),
            this.popoutContent));
    };
    SocialButton.propTypes = {
        network: PropTypes.string.isRequired,
        content: PropTypes.shape({
            siteUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            thumbnailUrl: PropTypes.string,
        }).isRequired,
        instanceId: PropTypes.string,
        componentId: PropTypes.string,
        sitePageId: PropTypes.string,
        channelId: PropTypes.string,
        biToken: PropTypes.string,
        videoId: PropTypes.string,
        rounded: PropTypes.bool,
        className: PropTypes.string,
        ariaLabel: PropTypes.string,
        popoutOuterClassName: PropTypes.string,
        popoutInnerClassName: PropTypes.string,
        linkClassName: PropTypes.string,
        tooltip: PropTypes.bool,
        onClick: PropTypes.func,
        isFocusable: PropTypes.bool,
    };
    SocialButton.defaultProps = {
        rounded: false,
        isFocusable: true,
        onClick: _.noop,
    };
    SocialButton = __decorate([
        connect(function (state) { return ({
            instanceId: getInstanceId(state),
            componentId: getCompId(state),
            sitePageId: getCurrentPageId(state),
            biToken: getBiToken(state),
        }); }),
        withStyles(styles)
    ], SocialButton);
    return SocialButton;
}(React.Component));
export default SocialButton;
