import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { reportStats } from 'widget/redux/server/actions/stats/report';
import STATS_EVENTS from 'shared/constants/stats/events';
import { openModal } from 'shared/redux/client/actions/modal';
import getUrlToVideo from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-video';
import { openBuyVideoOverlay, openSubscribeOverlay, openPaymentCompleteOverlay, } from 'widget/redux/client/actions/mobile-overlay-openners/payment-pages-actions';
import { openFullScreenPaymentCompleteOverlay } from 'shared/utils/open-overlay';
import { openFullScreenPurchaseOverlay, openFullScreenSubscribeOverlay, } from 'widget/utils/open-overlay';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getVideoIdFromUrl, getUrlParam, } from 'widget/selectors/query-params.js';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { areElementsRounded } from 'shared/selectors/app-settings';
import { toggleShareVideoOverlay } from 'widget/redux/client/actions/share-overlay';
import { isPayed } from 'shared/selectors/payments';
import ShareMobileContent from 'mobile/components/share-mobile/popup-content/popup-content';
import { URL_PARAM_STATE, URL_PARAM_PAYMENT_TYPE, URL_STATE_COMPLETE_PURCHASE, URL_STATE_PURCHASE, URL_STATE_SHARE, URL_STATE_SUBSCRIBE, } from '@wix/wix-vod-shared/dist/src/common/constants/url';
import { setCookie, hasCookie, removeCookie, } from '@wix/wix-vod-shared/dist/src/common/utils/cookie';
import { getCompId, getFullSiteUrl, } from 'widget/redux/client/hydrated-data/hydrated-data';
import { isMobile } from 'shared/selectors/form-factor';
var DISABLING_COOKIE_NAME = 'vod-disable-deeplink-popups';
var DeeplinkPopups = /** @class */ (function (_super) {
    __extends(DeeplinkPopups, _super);
    function DeeplinkPopups(props) {
        var _this = _super.call(this, props) || this;
        _this.sendStatsReport = function (name) {
            var _a = _this.props, reportStats = _a.reportStats, channelData = _a.channelData, videoData = _a.videoData;
            reportStats(STATS_EVENTS.VIDEO_SHARE, {
                channel: channelData,
                videoItem: videoData,
                network: {
                    name: name,
                },
            });
        };
        _this.state = {
            isShareOverlayOpened: false,
        };
        if (__SERVER__) {
            _this.hasOpenedPopup = true;
        }
        else {
            _this.hasOpenedPopup = hasCookie(DISABLING_COOKIE_NAME);
            window.onbeforeunload = function () {
                removeCookie(DISABLING_COOKIE_NAME);
            };
        }
        return _this;
    }
    DeeplinkPopups.prototype.componentDidMount = function () {
        this.openDeeplinkPopups(this.props);
    };
    DeeplinkPopups.prototype.UNSAFE_componentWillUpdate = function (nextProps) {
        this.openDeeplinkPopups(nextProps);
    };
    DeeplinkPopups.prototype.openDeeplinkPopups = function (props) {
        var hasOpenedPopup = this.hasOpenedPopup;
        var channelData = props.channelData, urlState = props.urlState, videoData = props.videoData;
        if (!urlState || hasOpenedPopup || !videoData || _.isEmpty(channelData)) {
            return;
        }
        switch (urlState) {
            case URL_STATE_SHARE:
                this.openSharePopup();
                break;
            case URL_STATE_COMPLETE_PURCHASE:
                this.openPurchaseCompletePopup();
                break;
            case URL_STATE_PURCHASE:
                this.openPurchasePopup(channelData, videoData);
                break;
            case URL_STATE_SUBSCRIBE:
                this.openSubscribePopup(channelData);
                break;
            default:
                break;
        }
        this.hasOpenedPopup = true;
        setCookie(DISABLING_COOKIE_NAME, true);
    };
    DeeplinkPopups.prototype.openSharePopup = function () {
        return this.props.isMobile
            ? this.openMobileSharePopup()
            : this.openShareOverlay();
    };
    DeeplinkPopups.prototype.openPurchasePopup = function (channel, video) {
        var openBuyVideoOverlay = this.props.openBuyVideoOverlay;
        if (isPayed(video)) {
            return;
        }
        return this.props.isMobile
            ? openBuyVideoOverlay(video.id)
            : this.props.openFullScreenPurchaseOverlay(channel.id, video.id);
    };
    DeeplinkPopups.prototype.openPurchaseCompletePopup = function () {
        var _a = this.props, channelData = _a.channelData, paymentType = _a.paymentType, videoData = _a.videoData;
        if (isPayed(videoData)) {
            return;
        }
        return this.props.isMobile
            ? this.props.openPaymentCompleteOverlay(paymentType, videoData.id)
            : this.props.openFullScreenPaymentCompleteOverlay(paymentType, channelData.id, videoData.id);
    };
    DeeplinkPopups.prototype.openSubscribePopup = function (channel) {
        var openSubscribeOverlay = this.props.openSubscribeOverlay;
        if (isPayed(channel)) {
            return;
        }
        return this.props.isMobile
            ? openSubscribeOverlay(channel.id)
            : this.props.openFullScreenSubscribeOverlay(channel.id);
    };
    DeeplinkPopups.prototype.openShareOverlay = function () {
        this.props.toggleShareVideoOverlay();
    };
    DeeplinkPopups.prototype.openMobileSharePopup = function () {
        var _a = this.props, siteUrl = _a.siteUrl, compId = _a.compId, videoData = _a.videoData, openModal = _a.openModal;
        var title = videoData.title, customCoverUrl = videoData.customCoverUrl, coverUrl = videoData.coverUrl, description = videoData.description;
        var shareParams = {
            siteUrl: getUrlToVideo(videoData, {
                siteUrl: siteUrl,
                compId: compId,
            }),
            title: title,
            description: description,
            coverUrl: customCoverUrl || coverUrl,
        };
        openModal({
            title: i18n.t('widget.share-video.share'),
            content: (React.createElement(ShareMobileContent, { shareParams: shareParams, inputLabel: "Accessible label", onShare: this.sendStatsReport })),
        });
    };
    DeeplinkPopups.prototype.render = function () {
        return null;
    };
    DeeplinkPopups.propTypes = {
        channelData: PropTypes.object,
        videoData: PropTypes.object,
        isMobile: PropTypes.bool,
        areElementsRounded: PropTypes.bool,
        openModal: PropTypes.func.isRequired,
        reportStats: PropTypes.func,
        urlState: PropTypes.string,
        paymentType: PropTypes.string,
        siteUrl: PropTypes.string,
        compId: PropTypes.string,
        openPaymentCompleteOverlay: PropTypes.func,
        openBuyVideoOverlay: PropTypes.func,
        openSubscribeOverlay: PropTypes.func,
        toggleShareVideoOverlay: PropTypes.func,
    };
    DeeplinkPopups = __decorate([
        connect(function (state) {
            var videos = getVideosGroupedByIds(state) || {};
            return {
                channelData: getChannelForWidget(state),
                videoData: videos[getVideoIdFromUrl(state)],
                urlState: getUrlParam(state, URL_PARAM_STATE),
                paymentType: getUrlParam(state, URL_PARAM_PAYMENT_TYPE),
                areElementsRounded: areElementsRounded(state),
                siteUrl: getFullSiteUrl(state),
                compId: getCompId(state),
                isMobile: isMobile(state),
            };
        }, {
            reportStats: reportStats,
            openBuyVideoOverlay: openBuyVideoOverlay,
            openSubscribeOverlay: openSubscribeOverlay,
            openPaymentCompleteOverlay: openPaymentCompleteOverlay,
            toggleShareVideoOverlay: toggleShareVideoOverlay,
            openFullScreenPurchaseOverlay: openFullScreenPurchaseOverlay,
            openFullScreenSubscribeOverlay: openFullScreenSubscribeOverlay,
            openFullScreenPaymentCompleteOverlay: openFullScreenPaymentCompleteOverlay,
            openModal: openModal,
        })
    ], DeeplinkPopups);
    return DeeplinkPopups;
}(React.Component));
export default DeeplinkPopups;
