import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { ResponsiveResizedImage } from '@wix/wix-vod-shared/dist/src/widget/ui-components/responsive-resized-image/responsive-resized-image';
import { withStyles } from 'shared/utils/withStyles';
import styles from '../overlay.styl';
var OverlayVideoCover = /** @class */ (function (_super) {
    __extends(OverlayVideoCover, _super);
    function OverlayVideoCover() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OverlayVideoCover.prototype.render = function () {
        var _a = this.props, videoItem = _a.videoItem, className = _a.className, width = _a.width, height = _a.height, withFallbackColor = _a.withFallbackColor;
        return (React.createElement(ResponsiveResizedImage, { className: classnames(styles.cover, className), src: getVideoCoverUrl(videoItem), alt: videoItem.title, withFallbackColor: withFallbackColor, stretchToContainer: true, breakpoints: [
                {
                    min: 0,
                    width: width,
                    height: height,
                },
            ] }));
    };
    OverlayVideoCover.propTypes = {
        videoItem: PropTypes.object.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        className: PropTypes.string,
        withFallbackColor: PropTypes.bool,
    };
    OverlayVideoCover.defaultProps = {
        withFallbackColor: false,
    };
    OverlayVideoCover = __decorate([
        withStyles(styles)
    ], OverlayVideoCover);
    return OverlayVideoCover;
}(React.Component));
export default OverlayVideoCover;
