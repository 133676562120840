import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { getResizedImageUrl } from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
import { buildUrl } from '@wix/wix-vod-shared/dist/src/common/utils/url/build-url';
import socialNetworkNames, { shareMappingPath, } from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';
import getUrlToWidget from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-widget';
export default function initSocialShare() {
    function getOrigin(siteUrl) {
        try {
            return new URL(siteUrl).origin;
        }
        catch (er) {
            var a = document.createElement('a');
            a.setAttribute('href', siteUrl);
            return a.protocol + "//" + a.host;
        }
    }
    function getShareProxyUrl(siteUrl, _a) {
        var instanceId = _a.instanceId, componentId = _a.componentId, sitePageId = _a.sitePageId, channelId = _a.channelId, videoId = _a.videoId, biToken = _a.biToken;
        var baseUrl = getOrigin(siteUrl);
        return buildUrl(baseUrl + "/" + shareMappingPath + "/" + sitePageId, {
            'instance-id': instanceId,
            'component-id': componentId,
            'channel-id': channelId,
            'video-id': videoId,
            'bi-token': biToken,
        });
    }
    return {
        facebook: {
            name: socialNetworkNames.FACEBOOK,
            getShareUrl: function (content, params) {
                var shareProxyUrl = getShareProxyUrl(content.siteUrl, params);
                return buildUrl('https://www.facebook.com/sharer/sharer.php', {
                    ref: 'site',
                    display: 'popup',
                    u: shareProxyUrl,
                });
            },
        },
        twitter: {
            name: socialNetworkNames.TWITTER,
            getShareUrl: function (content, params) {
                var siteUrl = content.siteUrl, title = content.title;
                var shareProxyUrl = getShareProxyUrl(siteUrl, params);
                return buildUrl('https://twitter.com/intent/tweet', {
                    original_referer: siteUrl,
                    text: i18n.t('social-share.network.twitter', {
                        title: title,
                        siteUrl: shareProxyUrl,
                    }),
                });
            },
        },
        pinterest: {
            name: socialNetworkNames.PINTEREST,
            getShareUrl: function (content, params) {
                var siteUrl = content.siteUrl, coverUrl = content.coverUrl, title = content.title, description = content.description;
                var shareProxyUrl = getShareProxyUrl(siteUrl, params);
                return buildUrl('https://www.pinterest.com/pin/create/button/', {
                    url: shareProxyUrl,
                    media: getResizedImageUrl({ url: coverUrl }),
                    description: i18n.t('social-share.network.pinterest', {
                        title: title,
                        description: description,
                    }),
                });
            },
        },
        tumblr: {
            name: socialNetworkNames.TUMBLR,
            getShareUrl: function (content, params) {
                var title = content.title, siteUrl = content.siteUrl, coverUrl = content.coverUrl;
                var shareProxyUrl = getShareProxyUrl(siteUrl, params);
                return buildUrl('https://www.tumblr.com/widgets/share/tool', {
                    posttype: 'photo',
                    // todo: need to find a way to resize image for tumblr
                    content: getResizedImageUrl({ url: coverUrl }),
                    caption: i18n.t('social-share.network.tumblr', { title: title, siteUrl: siteUrl }),
                    url: shareProxyUrl,
                });
            },
        },
        link: {
            name: socialNetworkNames.LINK,
            getShareUrl: function (content, params) {
                return getUrlToWidget(content.siteUrl, params.componentId);
            },
        },
    };
}
