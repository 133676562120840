import { isVideoOverQuota } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/quota';
import { convertSToMS } from '@wix/wix-vod-shared/dist/src/widget/utils/time';
import { logBi } from 'shared/worker/actions/bi';
import fedopsLogger from 'loggers/fedops/new-fedops-logger';
import { isAutoplayed } from 'shared/selectors/player';
export var logWidgetVidClick = function (_a) {
    var videoItem = _a.videoItem, channelData = _a.channelData, _b = _a.buttonName, buttonName = _b === void 0 ? 'play_video' : _b;
    return function (dispatch) {
        var durationSec = videoItem.durationSec, videoSource = videoItem.videoSource;
        dispatch(logBi('widget.vid.clicked', {
            duration: convertSToMS(durationSec),
            channelID: channelData.id,
            videoType: videoSource,
            videoID: videoItem.id,
            overQuoted: isVideoOverQuota(videoItem),
            buttonName: buttonName,
        }));
        dispatch(fedopsLogger.playVideo({ videoSource: videoSource }).start());
    };
};
export var logWidgetVidPlayStart = function (_a) {
    var channelData = _a.channelData, videoItem = _a.videoItem, button = _a.button;
    return function (dispatch, getState) {
        var isAutoplay = isAutoplayed(getState());
        var baseItemId = videoItem.baseItemId, id = videoItem.id, durationSec = videoItem.durationSec, videoSource = videoItem.videoSource;
        dispatch(logBi('widget.vid.start.play', {
            duration: convertSToMS(durationSec),
            channelID: channelData.id,
            videoType: videoSource,
            videoID: baseItemId || id,
            isAutoplay: isAutoplay,
            button: button,
        }));
        if (!isAutoplay) {
            dispatch(fedopsLogger.playVideo({ videoSource: videoSource }).end());
        }
    };
};
