import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import SocialShare from 'widget/player-widget/components/social-share/social-share';
import LinkIcon from 'static/images/link.svg';
import CheckedMarkIcon from 'static/images/check-mark.svg';
import classnames from 'classnames';
import { withStyles } from 'shared/utils/withStyles';
import styles from './popup-content.styl';
var ShareMobileContent = /** @class */ (function (_super) {
    __extends(ShareMobileContent, _super);
    function ShareMobileContent(props) {
        var _this = _super.call(this, props) || this;
        _this.handleInputRef = function (ref) { return (_this.inputRef = ref); };
        _this.handleCopyClick = function () {
            var input = _this.inputRef;
            input.select();
            input.setSelectionRange(0, input.value.length);
            document.execCommand('copy');
            input.blur();
            _this.setState({ copied: true });
            _this.timeout = setTimeout(function () { return _this.setState({ copied: false }); }, 3000);
        };
        _this.timeout = null;
        _this.inputRef = null;
        _this.state = {
            copied: false,
        };
        return _this;
    }
    ShareMobileContent.prototype.componentWillUnmount = function () {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    };
    ShareMobileContent.prototype.render = function () {
        var _a;
        var _b = this.props, shareParams = _b.shareParams, onShare = _b.onShare, inputLabel = _b.inputLabel, channelId = _b.channelId, videoId = _b.videoId;
        return (React.createElement("div", { "data-hook": "share-popup" },
            React.createElement("div", { className: classnames(styles.url, (_a = {},
                    _a[styles.copied] = this.state.copied,
                    _a)) },
                React.createElement("input", { type: "text", className: styles['hidden-input'], ref: this.handleInputRef, value: shareParams.siteUrl, onChange: _.noop }),
                React.createElement("input", { type: "text", "data-hook": "share-url-input", "aria-label": inputLabel, className: styles['url-input'], value: shareParams.siteUrl, onChange: _.noop }),
                React.createElement("div", { className: styles['copy-button'], onClick: this.handleCopyClick },
                    React.createElement(LinkIcon, { className: styles['link-icon'] })),
                React.createElement("div", { className: styles.notification },
                    React.createElement(CheckedMarkIcon, { className: styles['checked-icon'] }),
                    React.createElement("span", null, i18n.t('share-overlay.social.link-copied')))),
            React.createElement(SocialShare, { networks: ['facebook', 'twitter', 'pinterest', 'tumblr'], className: styles['share-container'], buttonClassName: styles['share-button'], content: shareParams, onShare: onShare, channelId: channelId, videoId: videoId })));
    };
    ShareMobileContent.propTypes = {
        inputLabel: PropTypes.string.isRequired,
        shareParams: PropTypes.object,
        onShare: PropTypes.func,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
    };
    ShareMobileContent = __decorate([
        withStyles(styles)
    ], ShareMobileContent);
    return ShareMobileContent;
}(React.Component));
export default ShareMobileContent;
